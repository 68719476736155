import React from "react";
import styled from "@emotion/styled";
import {
  Avatar,
  Typography,
  List,
  ListItem as ListItemListData,
  ListItemText as MuiListItemText,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";
import { fetchPreviousConversation } from "../../../../../redux/slices/conversationalActions";
import ChatContactHistory from "./ChatContactHistory";
const ListItemText = styled(MuiListItemText)(spacing);
const ListItemList = styled(ListItemListData)`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(2)};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  cursor: pointer;
`;

const PreviousConversation = () => {
  const { previousHistory } = useSelector(
    (state) => state.conversationalAction
  );
  const { contactData } = useSelector((state) => state.chatSettings);
  const { chatFromoneSpocData } = useSelector((state) => state.socketsSlice);

  const [contactID, setContactID] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    setContactID(contactData._id);
  }, [contactData]);
  React.useEffect(() => {
    !!contactID && dispatch(fetchPreviousConversation(contactID));
  }, [contactID, dispatch, chatFromoneSpocData, contactData]);

  // show chat history
  const [openAdd, setOpenAdd] = React.useState({
    Open: false,
    data: "",
  });
  const handleAddClickOpen = (item) => {
    setOpenAdd({
      Open: true,
      data: item,
    });
  };

  const handleAddClose = () => {
    setOpenAdd({
      Open: false,
      data: "",
    });
  };
  return (
    <>
      <List>
        {!!previousHistory ? (
          previousHistory.map((item, i) => (
            <ListItemList
              key={i}
              onClick={() => {
                handleAddClickOpen(item);
              }}
            >
              <Avatar
                alt={item.contactData[0].name}
                src={item.contactData[0].photo}
                style={{ marginRight: "5px" }}
              ></Avatar>
              <ListItemText
                primary={item.contactData[0].name}
                secondary={
                  ("0" + new Date(item.created_at).getDate()).slice(-2) +
                  "/" +
                  ("0" + (new Date(item.created_at).getMonth() + 1)).slice(-2) +
                  "/" +
                  new Date(item.created_at).getFullYear() +
                  " " +
                  ("0" + new Date(item.created_at).getHours()).slice(-2) +
                  ":" +
                  ("0" + new Date(item.created_at).getMinutes()).slice(-2) +
                  " ( " +
                  item.channel_id.split("_")[0] +
                  " ) "
                }
              />
            </ListItemList>
          ))
        ) : (
          <Typography>
            There are no Previous Conversations associated to this Contacts.
          </Typography>
        )}
      </List>
      <ChatContactHistory
        openAdd={openAdd}
        handleAddClose={handleAddClose}
        handleAddClickOpen={handleAddClickOpen}
      />
    </>
  );
};

export default PreviousConversation;
