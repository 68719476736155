import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Badge,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  IconButton,
  Typography as MuiTypography,
  Divider as MuiDivider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import {
  chatConversation,
  chatSettingsList,
  fetchCurrentChat,
} from "../../../../redux/slices/chatSettings";
import { fetchTodayschat } from "../../../../redux/slices/socketsSlice";
import moment from "moment";
import { isValidToken } from "../../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../redux/slices/login";
import { useNavigate } from "react-router-dom";
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  color: ${(props) => props.theme.palette.secondary.main};
`;
const Search = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e7f2ff;
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const ListContainer = styled(List)`
  overflow-y: scroll;
  height: 80vh;
`;
var chatFilterListData = [];
const ChatSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  const { chatList, conversationId } = useSelector(
    (state) => state.chatSettings
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const { todaysChat } = useSelector((state) => state.socketsSlice);

  const [current, setCurrent] = React.useState([]);
  const [resolved, setResolved] = React.useState([]);
  const [allChat, setAllChat] = React.useState([]);
  const [queueChat, setQueueChat] = React.useState([]);
  //const [queueChat, setQueueChat] = React.useState([]);

  var [chatListData, setChatListData] = React.useState([]);

  React.useEffect(() => {
    dispatch(fetchTodayschat());
  }, [dispatch]);

  React.useEffect(() => {
    setCurrent([]);
    setResolved([]);
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          !!item.chatAssign &&
          parseInt(item.is_queue_chat) === 0 &&
          item.chatAssign.map(
            (agentData) =>
              agentData.assignee_id === user.uid &&
              (parseInt(item.status) === 0
                ? setCurrent((current) => [...current, item])
                : setResolved((resolved) => [...resolved, item]))
          )
      );
  }, [todaysChat, user.uid]);

  React.useEffect(() => {
    setAllChat([]);
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          !!item.chatAssign &&
          parseInt(item.is_queue_chat) === 0 &&
          item.chatAssign.map(
            (chatitem) =>
              chatitem.assignee_id === user.uid &&
              setAllChat((allChat) => [...allChat, item])
          )
      );
  }, [todaysChat, user.uid]);

  React.useEffect(() => {
    setQueueChat([]);
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          !!item.chatAssign &&
          parseInt(item.is_queue_chat) === 1 &&
          parseInt(item.is_queue_confirm) === 0 &&
          item.chatAssign.map(
            (chatitem) =>
              chatitem.assignee_id === user.uid &&
              setQueueChat((queueChat) => [...queueChat, item])
          )
      );
  }, [todaysChat, user.uid]);

  React.useEffect(() => {
    chatList === "" && setChatListData(current);
    !!chatList &&
      chatList === "Current" &&
      !!current &&
      setChatListData(current);
    !!chatList &&
      chatList === "Resolved" &&
      !!resolved &&
      setChatListData(resolved);
    !!chatList && chatList === "All" && !!allChat && setChatListData(allChat);
    !!chatList &&
      chatList === "Queue" &&
      !!queueChat &&
      setChatListData(queueChat);
  }, [allChat, chatList, current, queueChat, resolved]);

  React.useEffect(() => {
    dispatch(chatSettingsList({ name: chatList, data: chatListData }));
  }, [chatList, chatListData, dispatch]);

  const getConversationDetails = (item) => {
    // console.log(
    //   "getConversationDetails ChatSidebar item == ",
    //   JSON.stringify(item)
    // );
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      !!item &&
        dispatch(
          chatConversation({
            conStatus: item.status,
            conversationId: item._id,
            botId: !!item.bot_id && item.bot_id,
            orgId: !!item.org_id && item.org_id,
            agentId:
              !!item.chatAssign[0].assignee_id &&
              item.chatAssign[0].assignee_id,
            channelId: !!item.channel_id && item.channel_id,
            contactId: !!item.contact_id && item.contact_id,
            contactData: item.contactDetails[0],
            recipientID: !!item.recipient_id && item.recipient_id,
          })
        );
      !!item && dispatch(fetchCurrentChat(item._id));
    }
  };

  // Search Conversation by Name
  var [search, getSearch] = React.useState("");

  chatFilterListData =
    search === ""
      ? chatListData
      : chatListData.filter((i) =>
          i.contactDetails[0].name.toLowerCase().includes(search.toLowerCase())
        );

  return (
    <>
      <Search className="search">
        <IconButton type="button" sx={{ p: "4px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          onChange={(e) => {
            getSearch(e.target.value);
          }}
        />
      </Search>
      <Wrapper>
        <TitleTypography variant="h4">
          {chatList} &nbsp;({chatFilterListData.length})
        </TitleTypography>
      </Wrapper>
      <Divider my={2} sx={{ borderBottomWidth: 2 }} />
      <ListContainer className="user-list">
        {!!chatFilterListData &&
          chatFilterListData.length > 0 &&
          chatFilterListData.map((item, i) =>
            !!conversationId && conversationId === item._id ? (
              <ListItem
                button
                key={i}
                onClick={() => {
                  getConversationDetails(item);
                }}
                className="selected"
              >
                <ListItemIcon>
                  <Online
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="dot"
                  >
                    <Avatar
                      alt={
                        !!item.contactDetails ? item.contactDetails[0].name : ""
                      }
                      src={
                        !!item.contactDetails
                          ? item.contactDetails[0].photo
                          : ""
                      }
                    />
                  </Online>
                </ListItemIcon>
                <ListItemText
                  primary={
                    item.contactDetails && item.contactDetails[0].name
                      ? item.contactDetails[0].name
                      : ""
                  }
                  secondary={
                    item.channel_id && item.channel_id.split("_")[0].length > 10
                      ? item.channel_id
                          .split("_")[0]
                          .toString()
                          .substring(0, 11) + "..."
                      : item.channel_id.split("_")[0]
                  }
                  style={{ display: "block", maxWidth: "180px" }}
                />
                {!!item &&
                !!item.unReadmessages &&
                item.unReadmessages.length > 0 ? (
                  <Badge
                    badgeContent={item.unReadmessages.length}
                    color="success"
                    mr={2}
                  >
                    <ListItemText
                      primary={
                        moment(new Date()).diff(
                          moment(item.chatAssign[0].updated_at),
                          "hours"
                        ) > 0
                          ? (
                              "0" +
                              moment(new Date()).diff(
                                moment(item.chatAssign[0].updated_at),
                                "hours"
                              )
                            ).slice(-2) + " hours ago"
                          : (
                              "0" +
                              moment(new Date()).diff(
                                moment(item.chatAssign[0].updated_at),
                                "minutes"
                              )
                            ).slice(-2) + " mins ago"
                      }
                      secondary={
                        !!item &&
                        !!chatList &&
                        chatList === "All" &&
                        (parseInt(item.status) === 0 ? "Current" : "Resolved")
                      }
                    />
                  </Badge>
                ) : (
                  <Badge
                    badgeContent={item.unReadmessages.length}
                    color="success"
                    mr={2}
                  >
                    <ListItemText
                      primary={
                        moment(new Date()).diff(
                          moment(item.chatAssign[0].updated_at),
                          "hours"
                        ) > 0
                          ? (
                              "0" +
                              moment(new Date()).diff(
                                moment(item.chatAssign[0].updated_at),
                                "hours"
                              )
                            ).slice(-2) + " hours ago"
                          : (
                              "0" +
                              moment(new Date()).diff(
                                moment(item.chatAssign[0].updated_at),
                                "minutes"
                              )
                            ).slice(-2) + " mins ago"
                      }
                      secondary={
                        !!item &&
                        !!chatList &&
                        chatList === "All" &&
                        (parseInt(item.status) === 0 ? "Current" : "Resolved")
                      }
                    />{" "}
                  </Badge>
                )}
              </ListItem>
            ) : (
              <ListItem
                button
                key={i}
                onClick={() => {
                  getConversationDetails(item);
                }}
              >
                <ListItemIcon>
                  <Online
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="dot"
                  >
                    <Avatar
                      alt={
                        !!item.contactDetails.length &&
                        item.contactDetails.length > 0
                          ? item.contactDetails[0].name
                          : ""
                      }
                      src={
                        !!item.contactDetails.length &&
                        item.contactDetails.length > 0
                          ? item.contactDetails[0].photo
                          : ""
                      }
                    />
                  </Online>
                </ListItemIcon>
                <ListItemText
                  primary={
                    item.contactDetails && item.contactDetails[0].name
                      ? item.contactDetails[0].name
                      : ""
                  }
                  secondary={
                    item.channel_id && item.channel_id.split("_")[0].length > 10
                      ? item.channel_id
                          .split("_")[0]
                          .toString()
                          .substring(0, 11) + "..."
                      : item.channel_id.split("_")[0]
                  }
                  style={{ display: "block", maxWidth: "180px" }}
                />
                {!!item &&
                !!item.unReadmessages &&
                item.unReadmessages.length > 0 ? (
                  <Badge
                    badgeContent={item.unReadmessages.length}
                    color="success"
                    mr={2}
                  >
                    <ListItemText
                      primary={
                        moment(new Date()).diff(
                          moment(item.chatAssign[0].updated_at),
                          "hours"
                        ) > 0
                          ? (
                              "0" +
                              moment(new Date()).diff(
                                moment(item.chatAssign[0].updated_at),
                                "hours"
                              )
                            ).slice(-2) + " hours ago"
                          : (
                              "0" +
                              moment(new Date()).diff(
                                moment(item.chatAssign[0].updated_at),
                                "minutes"
                              )
                            ).slice(-2) + " mins ago"
                      }
                      secondary={
                        !!item &&
                        !!chatList &&
                        chatList === "All" &&
                        (parseInt(item.status) === 0 ? "Current" : "Resolved")
                      }
                    />
                  </Badge>
                ) : (
                  <Badge
                    badgeContent={item.unReadmessages.length}
                    color="success"
                    mr={2}
                  >
                    <ListItemText
                      primary={
                        moment(new Date()).diff(
                          moment(item.chatAssign[0].updated_at),
                          "hours"
                        ) > 0
                          ? (
                              "0" +
                              moment(new Date()).diff(
                                moment(item.chatAssign[0].updated_at),
                                "hours"
                              )
                            ).slice(-2) + " hours ago"
                          : (
                              "0" +
                              moment(new Date()).diff(
                                moment(item.chatAssign[0].updated_at),
                                "minutes"
                              )
                            ).slice(-2) + " mins ago"
                      }
                      secondary={
                        !!item &&
                        !!chatList &&
                        chatList === "All" &&
                        (parseInt(item.status) === 0 ? "Current" : "Resolved")
                      }
                    />{" "}
                  </Badge>
                )}
              </ListItem>
            )
          )}
      </ListContainer>
    </>
  );
};

export default ChatSidebar;
