import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@mui/material";

// import botObj from "./botObj";
import BotContainer from "./BotContainer";
import moment from "moment";
import { useSelector } from "react-redux";

const BotLogsHistory = ({
  openBotAdd,
  handleAddBotClose,
  handleAddBotClickOpen,
}) => {
  const contentRef = useRef(null);
  const { botLogsFetchData } = useSelector((state) => state.botlogs);

  // console.log("botLogsFetchData  == " + JSON.stringify(botLogsFetchData?.data));

  const [contactData, setContactData] = useState({});
  const [channelId, setChannelId] = useState("");

  const [data, setData] = useState([]);

  // Validation function to check if contactData is valid
  const validateContactData = (data) => {
    return data && typeof data === "object" && Object.keys(data).length > 0;
  };

  //setData(botObj?.logs);
  useEffect(() => {
    botLogsFetchData?.data && botLogsFetchData?.data?.data?.logs?.length > 0
      ? setData(botLogsFetchData?.data?.data?.logs)
      : setData([]);
  }, [botLogsFetchData]);

  useEffect(() => {
    // Check if openBotAdd is valid and has contactData
    if (openBotAdd && validateContactData(openBotAdd.contactData)) {
      setContactData(openBotAdd.contactData); // Set valid contactData
    } else {
      setContactData({}); // Reset contactData if invalid
    }
  }, [openBotAdd]);

  useEffect(() => {
    // Check if openBotAdd is valid and has contactData
    if (openBotAdd && openBotAdd.channelId) {
      setChannelId(openBotAdd.channelId); // Set valid contactData
    } else {
      setChannelId(""); // Reset contactData if invalid
    }
  }, [openBotAdd]);

  // Helper Datetime function here
  const formatDate = (createdAt) => {
    const date = moment.utc(createdAt).add(5, "hours").add(30, "minutes");

    return date.isSame(moment(), "day")
      ? date.format("hh:mm A")
      : date.format("MMM-DD hh:mm A");
  };

  return (
    <>
      <Dialog
        open={openBotAdd.Open}
        onClose={handleAddBotClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ padding: "0px !important" }}
      >
        <DialogTitle
          id="form-dialog-title"
          //style={{ backgroundColor: "#E3EEFB" }}
        >
          <ListItem>
            <ListItemIcon>
              <Avatar
                alt={!!contactData ? contactData.name : ""}
                src={!!contactData ? contactData.photo : ""}
                sx={{ width: 47, height: 47 }}
              />
            </ListItemIcon>
            <ListItemText
              primary={!!contactData ? contactData.name : ""}
              secondary={!!channelId ? `from ${channelId.split("_")[0]}` : ""}
              style={{ marginLeft: "10px" }}
              primaryTypographyProps={{
                style: { textTransform: "capitalize" },
              }}
            />
            <ListItemText
              primary="Bot Logs"
              primaryTypographyProps={{
                style: { fontSize: "18px", marginLeft: "10px" },
              }}
            />
          </ListItem>
        </DialogTitle>
        <DialogContent
          sx={{ border: "none !important", padding: "5px !important" }}
        >
          <DialogContentText>
            <Box
              component="span"
              sx={{
                display: "inline-block",
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  margin: "5px",
                }}
              >
                <CardContent
                  className="botLogsHistory chatsbackground"
                  ref={contentRef} // Attach useRef to the CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    backgroundColor: "rgba(255, 255, 255, 0.6)", // Adds a semi-transparent white background to mimic opacity
                    minWidth: "550px",
                    backgroundImage: `url(/static/img/samples/fbe41cd97ada760e7b38de1be18301a5.jpg)`, // Background image
                    backgroundSize: "10%", // Ensures the image covers the entire area
                    backgroundBlendMode: "overlay", // Blends the transparent color with the image
                    backgroundAttachment: "fixed",
                    height: "calc(-35px + 68vh)",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  {data
                    .map((item, index) =>
                      item.sentByBot === true ? (
                        // Left-aligned chat bubble
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          key={index}
                        >
                          <Card
                            variant="outlined"
                            sx={{
                              maxWidth: "250px",
                              padding: "5px",
                            }}
                          >
                            <CardContent>
                              <BotContainer
                                type={item?.data?.type}
                                data={item?.data}
                                lastdata=""
                              />
                            </CardContent>
                            <div
                              style={{
                                color: "gray",
                                display: "block",
                                width: "100px !important",
                                padding: "4px",
                              }}
                            >
                              {formatDate(item?.createdAt)}
                            </div>
                          </Card>
                        </Box>
                      ) : (
                        // Right-aligned chat bubble
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Card
                            variant="outlined"
                            sx={{
                              maxWidth: "250px",
                              padding: "5px",
                              backgroundColor: "#1180FF",
                              color: "#fff",
                            }}
                            key={index}
                          >
                            <CardContent>
                              <BotContainer
                                type={item?.data?.type}
                                data={item?.data}
                                lastdata={index > 0 ? data[index + 1] : {}}
                              />
                            </CardContent>
                            <div
                              style={{
                                color: "#fff",
                                display: "block",
                                width: "100px !important",
                                padding: "4px",
                              }}
                            >
                              {formatDate(item?.createdAt)}
                            </div>
                          </Card>
                        </Box>
                      )
                    )
                    .sort((a, b) => b.createdAt - a.createdAt)}
                </CardContent>
              </Card>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddBotClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BotLogsHistory;
